export const menuItems = [
    {
        id: 0,
        name: 'main',
        to: 'top'
    },
    {
        id: 1,
        name: 'services',
        to: 'services'
    },
    {
        id: 2,
        name: 'barbers',
        to: 'barbers'
    },
    {
        id: 3,
        name: 'contacts',
        to: 'contacts'
    }
]